<template>
  <div>
    <OrderTaskFulfillment :business_entity_uuid="this.orderTask.business_entity_uuid"
                          :order_task_status="this.orderTaskStatus"
                          :jurisdiction_code="jurisdiction_code"
                          v-if="['CA', 'PA'].includes(jurisdiction_code)" />
    <h2 class="header">Corp Formation</h2>
    <div class="box">
      <div class="row">
        <div class="col" v-if="ifFutureFileDate">
          <dt>Future File Date</dt>
          <dd>{{ jurisdiction.future_filing_date | moment("MMMM DD YYYY") }}</dd>

          <dt>Filing Action</dt>
          <dd>{{ filingAction }}</dd>
        </div>
        <div class="col" v-else>
          <dt>Estimated Formation Date</dt>
          <dd>{{ corp_formation.estimated_formation_date | moment("MMMM DD YYYY") }}</dd>
        </div>
      </div>
    </div>
    <h2 class="header">Industry Info</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Industry</dt>
          <dd>{{ corp_formation.industry }}</dd>
        </div>
      </div>
    </div>
    <h2 class="header">Entity Info</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Entity Name <em v-if="comparedBusinessEntityName.isEdited">(edited)</em></dt>
          <dd>{{ comparedBusinessEntityName.getCanonicalValue() }}</dd>
        </div>
        <div class="col">
          <dt>Jurisdiction</dt>
          <dd>{{ corp_formation.jurisdiction }}</dd>
        </div>
        <div class="col">
          <dt>Entity Type</dt>
          <dd>CORP</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Alternate Names</dt>
          <dd><span v-for="name in corp_formation.alternates" v-bind:key="name">{{ name }}<br /></span></dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Business Purpose <em v-if="comparedBusinessPurpose.isEdited">(edited)</em></dt>
          <dd>{{ comparedBusinessPurpose.getCanonicalValue() }}</dd>
        </div>
        <div class="col">
          <dt>NAICS</dt>
          <dd v-if="businessEntity.naics_code">
            <SlotCopy :valueToCopy="businessEntity.naics_code">
              {{ businessEntity.naics_code }} ({{ getCodeLabel(businessEntity.naics_code) }})
            </SlotCopy>
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Authorized Signatory</dt>
          <dd v-if="authorizedSignatory">{{ this.computeFullNameOfPerson(authorizedSignatory) }}, {{ authorizedSignatory.titles | getContactTitles }}</dd>
          <dd v-else>
            None on file.
          </dd>
        </div>
      </div>
    </div>
    <h2 class="header">Address</h2>
    <div class="box">
      <div class="row">
        <div class="col-6">
          <dt>Physical Address</dt>
          <Person v-if="getAddressOfType('physical')" :person="getAddressOfType('physical')" >
            <UpdateAddressOrContactButton
              v-if="displayUpdateAddressOrContactButton && physicalAddress?.address_uuid"
              :entity="'address'"
              :subEntity="'address'"
              :recordType="'physical'"
              :recordUuid="physicalAddress.address_uuid"
              @openJourneyModal="openJourneyModal"
            />
          </Person>
          <div v-else>None on file.</div>
        </div>
        <div class="col-6" v-if="!businessEntity.has_vba">
          <dt>Mailing Address</dt>
          <Person v-if="getAddressOfType('mailing')" :person="getAddressOfType('mailing')" >
            <UpdateAddressOrContactButton
              v-if="displayUpdateAddressOrContactButton && mailingAddress?.address_uuid"
              :entity="'address'"
              :subEntity="'address'"
              :recordType="'mailing'"
              :recordUuid="mailingAddress.address_uuid"
              @openJourneyModal="openJourneyModal"
            />
          </Person>
          <div v-else>None on file.</div>
        </div>
        <div class="col-6" v-if="businessEntity.has_vba">
          <dt>Virtual Address<br/>(use this in place of mailing address on forms)</dt>
          <Person :person="getAddressOfType('virtual')" address-type="virtual" />
        </div>
      </div>
    </div>
    <h2 class="header">Ownership and Management</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Directors</dt>
          <dd v-for="(director, index) in comparedDirectors"  v-bind:key="director.first_name">
            <Person :person="director">
              <template v-slot:update-contact-button>
                <UpdateAddressOrContactButton
                  v-if="displayUpdateAddressOrContactButton && director?.contact_uuid"
                  :entity="'contact'"
                  :subEntity="'contact'"
                  :recordType="'director'"
                  :recordUuid="director.contact_uuid"
                  @openJourneyModal="openJourneyModal"
                />
              </template>
              <template v-slot:update-address-button>
                <UpdateAddressOrContactButton
                  v-if="displayUpdateAddressOrContactButton && director?.contact_uuid"
                  :entity="'contact'"
                  :subEntity="'address'"
                  :recordType="'director'"
                  :recordUuid="director.contact_uuid"
                  @openJourneyModal="openJourneyModal"
                />
              </template>
            </Person>
            <span v-if="index != Object.keys(comparedDirectors).length - 1"><hr/></span>
          </dd>
        </div>
        <div class="col">
          <dt>Officers</dt>
          <dd  v-for="(officer, index) in comparedOfficers" v-bind:key="officer.first_name">
            <Person :person="officer">
              <template v-slot:update-contact-button>
                <UpdateAddressOrContactButton
                  v-if="displayUpdateAddressOrContactButton && officer?.contact_uuid"
                  :entity="'contact'"
                  :subEntity="'contact'"
                  :recordType="'officer'"
                  :recordUuid="officer.contact_uuid"
                  @openJourneyModal="openJourneyModal"
                />
              </template>
              <template v-slot:update-address-button>
                <UpdateAddressOrContactButton
                  v-if="displayUpdateAddressOrContactButton && officer?.contact_uuid"
                  :entity="'contact'"
                  :subEntity="'address'"
                  :recordType="'officer'"
                  :recordUuid="officer.contact_uuid"
                  @openJourneyModal="openJourneyModal"
                />
              </template>
            </Person>
            <span v-if="index != Object.keys(comparedOfficers).length - 1"><hr/></span>
          </dd>
        </div>
      </div>
    </div>
    <h2 class="header">Contact</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Contact</dt>
          <dd><Person :person="compareContactData(orderTask.order_task_data.contact, 'contact')">
            <template v-slot:update-contact-button>
              <UpdateAddressOrContactButton
                v-if="displayUpdateAddressOrContactButton && compareContactData(orderTask.order_task_data.contact, 'contact')?.contact_uuid"
                :entity="'contact'"
                :subEntity="'contact'"
                :recordType="'primary'"
                :recordUuid="compareContactData(orderTask.order_task_data.contact, 'contact').contact_uuid"
                @openJourneyModal="openJourneyModal"
              />
            </template>
            <template v-slot:update-address-button>
              <UpdateAddressOrContactButton
                v-if="displayUpdateAddressOrContactButton && compareContactData(orderTask.order_task_data.contact, 'contact')?.contact_uuid"
                :entity="'contact'"
                :subEntity="'address'"
                :recordType="'primary'"
                :recordUuid="compareContactData(orderTask.order_task_data.contact, 'contact').contact_uuid"
                @openJourneyModal="openJourneyModal"
              />
            </template>
          </Person></dd>
        </div>
      </div>
    </div>
    <h2 class="header">Shares</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Par Value <em v-if="comparedParValue.isEdited">(edited)</em></dt>
          <dd>{{ comparedParValue.getCanonicalValue() }}</dd>
        </div>
        <div class="col">
          <dt>Authorized Shares <em v-if="comparedShares.isEdited">(edited)</em></dt>
          <dd>{{ comparedShares.getCanonicalValue() }}</dd>
        </div>
        <div class="col" v-if="jurisdiction_code === 'CA'">
          <dt>More than one class or series of shares <em v-if="comparedMoreThanOneShare.isEdited">(edited)</em></dt>
          <dd>{{ comparedMoreThanOneShare.getCanonicalValue() ? 'Yes' : 'No' }}</dd>
        </div>
      </div>
    </div>
    <template v-if="ccorpStockInformation && jurisdiction_code === 'DE'">
    <h2 class="header">CCorp Stock Information</h2>
    <div class="box" v-show="ccorpStockInformation">
      <div class="row">
        <div class="col">
          <dt>Par Value of Stock</dt>
          <dd>{{ ccorpStockInformation.par_value ? "$" + parseFloat(ccorpStockInformation.par_value).toFixed(5) : 0}}</dd>
        </div>
        <div class="col">
          <dt>Authorized Shares of Stock</dt>
          <dd>{{ ccorpStockInformation.share_qty ? ccorpStockInformation.share_qty : 0 }}</dd>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col">
          <dt>Expedite Fee</dt>
          <dd>{{ ccorpStockInformation.fees && ccorpStockInformation.fees.expedite_fee.fee_amount ? this.money(ccorpStockInformation.fees.expedite_fee.fee_amount) : 0 }}</dd>
        </div>
        <div class="col">
          <dt>Document Fee</dt>
          <dd>{{ ccorpStockInformation.fees && ccorpStockInformation.fees.state_document_fee.fee_amount ? this.money(ccorpStockInformation.fees.state_document_fee.fee_amount) : 0 }}</dd>
        </div>
        <div class="col">
          <dt>State Processing Fee</dt>
          <dd>{{ ccorpStockInformation.fees && ccorpStockInformation.fees.state_processing_fee.fee_amount ? this.money(ccorpStockInformation.fees.state_processing_fee.fee_amount) : 0 }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Base Stock Fee</dt>
          <dd>$15.00</dd>
        </div>
        <div class="col">
          <dt>Additional Stock Fees (Calculated)</dt>
          <dd>{{ ccorpStockInformation.stock_total_adjusted ? this.money(ccorpStockInformation.stock_total_adjusted) : 0 }}</dd>
        </div>
        <div class="col">
          <dt>Total Stock Fees</dt>
          <dd>{{ ccorpStockInformation.stock_total ? this.money(ccorpStockInformation.stock_total) : 0 }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Base Filing Fees (Initial Payment)</dt>
          <dd>{{ccorpStockInformation.fees && ccorpStockInformation.fees.expedite_fee.fee_amount ? this.money(15 + ccorpStockInformation.fees.expedite_fee.fee_amount + ccorpStockInformation.fees.state_document_fee.fee_amount + ccorpStockInformation.fees.state_processing_fee.fee_amount) : 0}}</dd>
        </div>
        <div class="col">
          <dt>Total Filing Fees</dt>
          <dd>{{ ccorpStockInformation.fees && ccorpStockInformation.fees.expedite_fee.fee_amount ? this.money(ccorpStockInformation.fees.expedite_fee.fee_amount + ccorpStockInformation.fees.state_document_fee.fee_amount + ccorpStockInformation.fees.state_processing_fee.fee_amount + parseFloat(ccorpStockInformation.stock_total)) : 0 }}</dd>
        </div>
        <div class="col">
        </div>
      </div>
    </div>
    </template>

    <template v-if="registeredAgent('corp_formation')">
      <h2 v-if="registeredAgent" class="header">Registered Agent</h2>
      <div v-if="registeredAgent" class="box">
        <div class="row">
          <div class="col">
            <dd><Person :person="registeredAgent('corp_formation')"></Person></dd>
          </div>
        </div>
      </div>
    </template>

    <UpdateAddressOrContactModal
      :isJourneyUpdateSuccess="isJourneyUpdateSuccess"
      :isJourneyUpdateSubmitting="isJourneyUpdateSubmitting"
      :modalData="modalData"
      v-if="isJourneyModalOpen"
      @closeJourneyModal="closeJourneyModal"
      @updateJourneyService="updateJourneyService(modalData)"
    />
  </div>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Person from '../../common/person';
import { computeFullNameOfPersonMixin, getContactTitlesMixin, moneyMixin, naicsCodesMixins } from '@/components/mixins';
import OrderTaskFulfillment from './order-task-fulfillment.vue'
import UpdateAddressOrContactButton from "@/components/business-entities/UpdateAddressOrContactButton.vue";
import UpdateAddressOrContactModal from "@/components/business-entities/UpdateAddressOrContactModal.vue";
import { getClient } from "@/client/ldClient";
import { minJourneyEnabled } from "@/assets/utils/feature-utils";

export default {
  name: 'CorpFormationView',

  components: {
    Person,
    OrderTaskFulfillment,
    UpdateAddressOrContactButton,
    UpdateAddressOrContactModal
  },

  mixins: [computeFullNameOfPersonMixin, getContactTitlesMixin, moneyMixin, naicsCodesMixins],

  props: ['orderTask'],
  data () {
    return {
      journeyData: {},
      journeyModalData: {},
      isJourneyModalOpen:false,
      isJourneyUpdateSubmitting: false,
      isJourneyUpdateSuccess: true,
      featureFlagClient: null,
      isFeatureEnabled: false,
      enableMiniJourneyFeatureFlag: false,
      modalData: {},
    }
  },
  async created() {
    this.featureFlagClients = await getClient();
    this.enableMiniJourneyFeatureFlag = minJourneyEnabled( this.featureFlagClients);
  },
  computed: {
    ...mapGetters({
      authorizedSignatory: 'businessEntities/businessEntityAuthorizedSignatory',
      compareContactData: 'businessEntities/compareContactData',
      compareListsOfContactData: 'businessEntities/compareListsOfContactData',
      compareEntityData: 'businessEntities/compareEntityData',
      ccorpStockInformation: 'fulfillment/getCCorpStockInformation',
      getAddressOfType: 'businessEntities/getAddressOfType',
      registeredAgent: 'orders/getRegisteredAgentData',
      getUuidBusinessEntity: 'orders/getUuidBusinessEntity'
    }),
    ...mapState({
      businessEntity: (state) => state.businessEntities.businessEntity,
      businessEntityTasks: (state) => state.businessEntities.businessEntityTasks,
      jurisdiction: (state) => state.businessEntities.jurisdictions[0],
    }),
    corp_formation() {
      return this.orderTask.order_task_data;
    },
    physicalAddress() {
      return this.getAddressOfType('physical');
    },
    mailingAddress() {
      return this.getAddressOfType('mailing');
    },
    jurisdiction_code() {
      return this.corp_formation.jurisdiction;
    },
    ifFutureFileDate() {
      return this.jurisdiction && this.jurisdiction.future_filing_date;
    },
    orderTaskStatus() {
      return this.orderTask.order_task_status;
    },
    filingAction() {
      if (!this.jurisdiction || !('filing_action' in this.jurisdiction)) {
        return ''
      }
      if (this.jurisdiction.filing_action === 'hold') {
        return 'Hold (Do not file paperwork until future filing date)';
      } if (this.jurisdiction.filing_action === 'immediate') {
        return 'Immediate (File paperwork now with the effective future filing date)';
      }
      return this.jurisdiction.filing_action;
    },
    comparedBusinessEntityName() {
      return this.compareEntityData(this.corp_formation.entity_name, 'business_entity_name')
    },
    comparedBusinessPurpose() {
      return this.compareEntityData(this.corp_formation.business_purpose, 'business_purpose')
    },
    comparedDirectors() {
      return this.compareListsOfContactData(this.corp_formation.directors, 'directors')
    },
    comparedOfficers() {
      return this.compareListsOfContactData(this.corp_formation.officers, 'officers')
    },
    comparedParValue() {
      // corp_formation / order_task_data stores par_value as a string of an integer
      // business_entity stores authorized_shares as a string of a 10-decimal number
      // compareEntityData uses strict type coercion

      // this is a problem
      // so we format the fake-integer value in order_task_data as a 10-decimal string number so strict equality works
      const parValue = parseInt(this.corp_formation.par_value).toFixed(10)
      return this.compareEntityData(parValue, 'par_value')
    },
    comparedShares() {
      // corp_formation / order_task_data stores authorized_shares as a string of an integer
      // business_entity stores authorized_shares as a number (integer)
      // compareEntityData uses strict type coercion

      // this is a problem
      // so we cast the string to an integer so strict equality works

      return this.compareEntityData(parseInt(this.corp_formation.authorized_shares), 'authorized_shares')
    },
    comparedMoreThanOneShare() {
      return this.compareEntityData(!!this.corp_formation.has_more_than_one_share, 'has_more_than_one_share')
    },
    displayUpdateAddressOrContactButton() {
      return this.enableMiniJourneyFeatureFlag && this.businessEntity.formation_status === 'not_formed'
    }
  },
  methods: {
    ...mapActions({
      fetchBusinessEntity: 'businessEntities/fetchBusinessEntity',
      fetchCorpStockInformation: 'fulfillment/fetchCorpStockInformation'
    }),
    openJourneyModal(journeyData) {
      this.modalData = journeyData;
      this.isJourneyUpdateSuccess = true;
      this.isJourneyModalOpen = true;
    },
    closeJourneyModal() {
      this.modalData = null;
      this.isJourneyUpdateSubmitting = false;
      this.isJourneyUpdateSuccess = true;
      this.isJourneyModalOpen = false;
    },
    async updateJourneyService(modalData) {
      this.isJourneyUpdateSubmitting = true;
      try {
        const businessEntityUuid = this.businessEntity.business_entity_uuid;
        const accountUuid = this.businessEntity.account_uuid;
        const orderTask = this.businessEntityTasks.find(task =>
          (task.order_task_type === 'llc_formation' || task.order_task_type === 'corp_formation') &&
          !['canceled'].includes(task.order_task_status)
        );
        const orderTaskUuid = orderTask?.order_task_uuid;
        const orderUuid = orderTask?.order_uuid;
        const payload = {
          entity: modalData.entity,
          journey: modalData.subEntity,
          recordUuid: modalData.recordUuid,
          recordType: modalData.recordType,
          businessEntityUuid,
          accountUuid,
          orderUuid,
          orderTaskUuid
        };

        await this.$store.dispatch('businessEntities/createAddressContactJourney', payload);
        this.closeJourneyModal();

      } catch (error) {
        this.closeJourneyModal();
      }
    }
  },
  mounted() {
    this.fetchBusinessEntity({ uuid: this.getUuidBusinessEntity });
    this.fetchCorpStockInformation({ order_task_uuid: this.orderTask.order_task_uuid })
  }
};
</script>
<style scoped>
dt{
  font-size: 14px;
  color: #969696;
}
</style>
