<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content min-width-fit-content">
        <div class="modal-header">
          <transition name="fade" mode="out-in" appear>
            <h5 class="modal-title" key="title" v-if="!isSubmitError">Are you sure you would like to submit a refund?</h5>
            <h5 class="modal-title" key="title-error" v-else>{{ refundErrMsg.primary }}</h5>
          </transition>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('close')"></button>
        </div>
        <transition name="fade" mode="out-in" appear>
          <div class="modal-body" key="body" v-if="!isSubmitError">
            <h5>Available to Refund: ${{refundable_amount}}</h5>
            <span><input type="checkbox" id="refund-all" @change="refundAll"><label for="refund-all"> &nbsp;Refund All</label></span>
            <br>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Service</th>
                  <th scope="col">Type</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Refund Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in refundableBillingItems" :key="item.uuid">
                  <td scope="row">{{ item.service }}</td>
                  <td v-html="item.price ? 'Price' : 'Fee'"></td>
                  <td v-html="item.price ? item.price : item.fees"></td>
                  <td>
                    <zg-numeric-input v-if="item.price > 0"
                      type="number"
                      class="form-control"
                      :label="`${item.uuid}-refund-price`"
                      :min=0
                      :max="item.price"
                      :step=.01
                      @change="updateAmountToRefund"
                      :disabled="isDeferred(item.service)"
                      v-model="item.refund_price"
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="getTooltipValue(item)"
                    />
                     <zg-numeric-input v-if="item.fees > 0"
                      type="number"
                      class="form-control"
                      :label="`${item.uuid}-refund-fee`"
                      :min=0
                      :max="item.fees"
                      :step=.01
                      @change="updateAmountToRefund"
                      :disabled="isDeferred(item.service)"
                      v-model="item.refund_fees"
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="getTooltipValue(item)"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Courtesy Discount <br><small>It will be applied to the total of the invoice</small></td>
                  <td></td>
                  <td></td>
                  <td><input type="number" class="amount form-control" min="0" :max="refundable_amount" step=".01" @change="updateAmountToRefund" v-model="courtesyDiscount"></td>
                </tr>
              </tbody>
            </table>
            <div>
              <h5>Applied coupons</h5>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in billingItems" :key="item.uuid">
                    <td v-if="item.coupon !== null">{{item.coupon.external_coupon_id}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>Amount: $<input class="amount form-control" v-model="refund.amount" disabled /></div>
            <div><label v-if="missingRefundAmount" class="invalid-input-message">Please provide an amount to refund.</label></div>
            <div><label v-if="refundAmountTooLarge" class="invalid-input-message">A maximum of ${{ refundable_amount }} can be refunded.</label></div>
            <div><label v-if="omittedDeferredItems" class="invalid-input-message">This amount does not include the following deferred item(s): {{ deferredSubscriptionServices.concat(deferredTransactionalServices).join(', ') }}.</label></div>
            <ReasonCollector 
              :listReasons="getRefundReasons"
              :description="'Reason for providing a refund:'"
              @input="setRefundReason"
            />
          </div>
          <div class="modal-body" key="body-error" v-else>
            <p v-html="refundErrMsg.secondary"></p>
          </div>
        </transition>
          <div class="modal-footer" key="footer">
            <transition name="fade" mode="out-in" appear>
              <div key="footer" v-if="!isSubmitError">
                <button class="btn btn-danger action" @click="handleRefund" :disabled="!inputIsValid || isSubmitting">
                  Yes, submit a refund
                  <transition name="btn-thinking" v-if="isSubmitting" appear>
                    <span class="btn__icon">
                      <icon-spinner />
                    </span>
                  </transition>
                </button>
              </div>
              <div key="footer-error" v-else>
                <button class="btn btn-danger action" @click="handleCloseModal">
                  I understand
                </button>
              </div>
            </transition>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { extractRefunds, parsePositiveNumber } from '../../utils/global-utils';
import { mapActions, mapGetters, mapState } from 'vuex';
import messages from '@/assets/messages';
import ReasonCollector from '@/components/common/forms/ReasonCollector.vue';
import IconSpinner from '@/components/common/images/IconSpinner'
import ZgNumericInput from '../../common/Generics/ZgNumericInput.vue';
import authService from '../../../auth/AuthService';

export default {
  name: 'RefundInvoiceItemModal',
  props: ['order_uuid', 'invoice_uuid', 'refundable_amount', 'deferredSubscriptionServices', 'deferredTransactionalServices'],

  components: {
    IconSpinner,
    ReasonCollector,
    ZgNumericInput
  },

  data() {
    return {
      refund: {
        invoice_uuid: this.invoice_uuid,
        amount: 0,
        refund_cancel_reason_definition: undefined,
        refund_reason_other: undefined,
        email: authService.getLoggedInEmail()
      },
      courtesyDiscount: 0,
      isSubmitError: false,
      isSubmitting: false,
      errorMsg: null
    };
  },

  computed: {
    ...mapState('orders', ['order', 'transactionalBillingItems', 'subscriptionBillingItems', 'billingInvoices']),
    ...mapGetters('orders', ['getSplitBillingItems', 'getRefundReasons']),
    missingRefundAmount() {
      return !parseFloat(this.refund.amount);
    },
    refundAmountTooLarge() {
      const amount = parseFloat(this.refund.amount);
      return (!isNaN(amount)) && (amount > this.refundable_amount);
    },
    reasonNotProvided() {
      return this.refund.refund_cancel_reason_definition
        && this.refund.refund_cancel_reason_definition.secondary_reason.toLowerCase() === 'other'
        && !this.refund.refund_reason_other.length;
    },
    omittedDeferredItems() {
      return this.billingItems.filter((item) => this.isDeferred(item.service)).length;
    },
    refundableBillingItems(){
      return this.billingItems.filter((item) => item.fees > 0 || item.price > 0 && !this.isDeferred(item.service) ? true : false)
    },
    inputIsValid() {
      return this.refund.refund_cancel_reason_definition && !this.refundAmountTooLarge && !this.missingRefundAmount && !this.reasonNotProvided;
    },
    billingItems() {
      return this.getSplitBillingItems.map(({
        service, fees, price, uuid, refunds, coupon
      }) => ({
        service, fees: parsePositiveNumber(fees) - extractRefunds(refunds, 'fee'), price: parsePositiveNumber(price) - extractRefunds(refunds, 'price') - this.getCouponValue(coupon), uuid, coupon
      }));
    },
    refundErrMsg() {
      return messages.issueRefund(this.errorMsg).error
    }
  },
  methods: {
    ...mapActions('orders', [
      'fetchRefunds',
      'fetchSubscriptionBillingItems',
      'fetchTransactionalBillingItems',
      'refundBillingInvoice'
    ]),
    setRefundReason(reason) {
      this.refund.refund_cancel_reason_definition = reason.refund_cancel_reason_definition;
      this.refund.refund_reason_other = reason.refund_reason_other;
    },
    handleCloseModal() {
      this.$emit('canceled')
    },
    handleRefund() {
      this.isSubmitting = true
      this.refundBillingInvoice({ uuid: this.order_uuid, data: this.refund })
        .then((response) => {
          this.fetchRefunds({ uuid: this.order_uuid });
          this.fetchTransactionalBillingItems({ uuid: this.order_uuid });
          this.fetchSubscriptionBillingItems({ uuid: this.order_uuid });
          this.isSubmitting = false
          if (response.status === 200) { 
            this.handleCloseModal()
          } else {
            this.handleRefundError(response)
          }
        })
    },
    handleRefundError (response) {
      this.errorMsg = response.data
      this.isSubmitError = true
      this.$emit('setRefundBtnEnabledStatus', !this.isSubmitError)
    },
    isDeferred(value) {
      return this.deferredSubscriptionServices.includes(value) || this.deferredTransactionalServices.includes(value);
    },
    updateAmountToRefund() {
      let amountToRefund = 0;
      const refundTrackingData = [];
      this.billingItems.forEach((item) => {
        amountToRefund = amountToRefund + (parseFloat(item.refund_fees) || 0) + (parseFloat(item.refund_price) || 0);
        const itemIsRefundable = item.refund_fees || item.refund_price;
        if (itemIsRefundable) {
          // save refund tracking data
          if(item.refund_fees){
            // create refund fees
            const itemToRefund = this.createRefundTrackingItem(item, 'fee');
            refundTrackingData.push(itemToRefund);
          }
          if (item.refund_price) {
            const itemToRefund = this.createRefundTrackingItem(item, 'price');
            refundTrackingData.push(itemToRefund);
          }
        }
      });
      this.refund.amount = parseFloat(amountToRefund) + (parseFloat(this.courtesyDiscount) || 0);
      // add courtesy discount if necessary
      if (this.courtesyDiscount && parseFloat(this.courtesyDiscount) > 0) {
        const item = {
          charge_type: 'price',
          amount: this.courtesyDiscount,
          product: 'courtesy_writeoff'
        };
        refundTrackingData.push(item);
      }
      this.refund.tracking = refundTrackingData;
    },
    createRefundTrackingItem(item, chargeType) {
      const {uuid, refund_price, refund_fees} = item
      const refundTrackingItem = {
        order_billing_item_uuid: uuid,
        amount: chargeType === 'price' ? refund_price : refund_fees,
        charge_type:chargeType
      };
      return refundTrackingItem;
    },
    refundAll(e) {
      const isChecked = e.target.checked;
      this.billingItems.filter((item) => !this.isDeferred(item.service)).forEach((item) => {
        if (item.price) {
          if (isChecked) {
            item.refund_price = item.price;
          } else {
            item.refund_price = null;
          }
        }
        if (item.fees) {
          if (isChecked) {
            item.refund_fees = item.fees;
          } else {
            item.refund_fees = null;
          }
        }
      });
      this.updateAmountToRefund();
    },
    getCouponValue(coupon) {
      if (coupon === null) {
        return 0;
      }
      return parsePositiveNumber(coupon.amount);
    },
    getTooltipValue(item) {
      if (this.isDeferred(item.service)) {
        return 'Service is Deferred - Cannot Refund'
      } else {
        return 'Amount to be Refunded'
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss" scoped>
.badge {
  text-transform: capitalize;
}

.invalid-input-message {
  color: $clr-danger;
}

input:invalid{
  border: 1px solid $clr-danger;
}

.btn {
  transition: opacity 800ms $easeInOutQuad;
  &__icon {
    display: inline-block;
    width: 1.5em;
  }
  &-thinking {
    &-enter {
      width: 0em;
      &-active {
        transition: width 400ms $easeOutExpo;
      }
      &-to {
        width: 1.5em;
      }
    }
    &-leave {
      width: 0em;
      &-active {
        transition: width 300ms $easeInExpo;
      }
      &-to {
        width: 0;
      }
    }
  }
}
.fade {
  &-enter-active, &-leave-active {
    transition: opacity 450ms $easeInOutQuad;
  }
  &-enter, &-leave-to {
    opacity: 0;
  }
}

.modal {
  p {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
