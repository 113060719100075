<template>
  <div>
    <automated-filing
      :jurisdiction="jurisdiction_code"
      :automatedFiling="getAutomatedFiling(false)"
      :orderUuid="getUuidOrder"
      :filingType="'llc_formation'"
      :isTest="false"
    ></automated-filing>
    <automated-filing
      :jurisdiction="jurisdiction_code"
      :automatedFiling="getAutomatedFiling(true)"
      :orderUuid="getUuidOrder"
      :isTest="true"
    ></automated-filing>
    <OrderTaskFulfillment :business_entity_uuid="orderTask.business_entity_uuid" :accountUuid="accountUuid" :businessEntityUuid="businessEntityUuid" :order_task_status="orderTaskStatus" :jurisdiction_code="jurisdiction_code" v-if="agencyLoginSelectionEnabled" />
    <h2 class="header">LLC Formation</h2>
    <div class="box">
      <span v-if="hasManagersAndFL"><strong>FILE MANUALLY, company has more than 6 members and managers combined in FL</strong></span>
      <ReportValidationMessages :messages="automationMessages"/>
      <div class="row">
        <div class="col" v-if="ifFutureFileDate">
          <dt>Future File Date</dt>
          <dd>{{ orderTask.jurisdiction_future_filing_date | moment("MMMM DD YYYY") }}</dd>

          <dt>Filing Action</dt>
          <dd>{{ filingAction }}</dd>
        </div>
        <div class="col" v-else>
          <dt>Estimated Formation Date</dt>
          <dd>{{ llcFormation.estimated_formation_date | moment("MMMM DD YYYY") }}</dd>
        </div>
      </div>
    </div>

    <h2 class="header">Industry Info</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Industry</dt>
          <dd>{{ llcFormation.industry }}</dd>
        </div>
      </div>
    </div>
    <h2 class="header">Entity Info</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Entity Name <em v-if="comparedBusinessEntityName.isEdited">(edited)</em></dt>
          <dd>{{ comparedBusinessEntityName.getCanonicalValue() }}</dd>
        </div>
        <div class="col">
          <dt>Jurisdiction</dt>
          <dd>{{ llcFormation.jurisdiction }}</dd>
        </div>
        <div class="col">
          <dt>Entity Type</dt>
          <dd>LLC</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Alternate Names</dt>
          <dd><span v-for="name in llcFormation.alternates" v-bind:key="name">{{ name }}<br /></span></dd>
        </div>
        <div class="col" v-if="llcFormation.formation_county">
          <dt>LLC Office County</dt>
          <dd>{{ llcFormation.formation_county }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Business Purpose <em v-if="comparedBusinessPurpose.isEdited">(edited)</em></dt>
          <dd>{{ comparedBusinessPurpose.getCanonicalValue() }}</dd>
        </div>
        <div class="col">
          <dt>NAICS</dt>
          <dd v-if="businessEntity.naics_code">
            <SlotCopy :valueToCopy="businessEntity.naics_code">
              {{ businessEntity.naics_code }} ({{ getCodeLabel(businessEntity.naics_code) }})
            </SlotCopy>
          </dd>
          <dd v-else>None on file.</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Fiscal Year End Month</dt>
          <dd>{{ fiscalEndOfYearMonth }}</dd>
        </div>
      </div>
    </div>
    <h2 class="header">Address</h2>
    <div class="box">
      <div class="row">
        <div class="col-6">
          <dt>Physical Address</dt>
          <dd v-if="addresses['physical']">
            <Person :person="addresses['physical']">
              <UpdateAddressOrContactButton
                v-if="displayUpdateAddressOrContactButton && addresses['physical']?.address_uuid"
                :entity="'address'"
                :subEntity="'address'"
                :recordType="'physical'"
                :recordUuid="addresses['physical'].address_uuid"
                @openJourneyModal="openJourneyModal"
              >
              </UpdateAddressOrContactButton>
            </Person>
          </dd>
          <div v-else>No address on file.</div>
        </div>
        <div class="col-6" v-if="!getHasVBA">
          <dt>Mailing Address</dt>
          <dd v-if="addresses['mailing']">
            <Person :person="addresses['mailing']">
              <UpdateAddressOrContactButton
                v-if="displayUpdateAddressOrContactButton && addresses['mailing']?.address_uuid"
                :entity="'address'"
                :subEntity="'address'"
                :recordType="'mailing'"
                :recordUuid="addresses['mailing'].address_uuid"
                @openJourneyModal="openJourneyModal"
              >
              </UpdateAddressOrContactButton>
            </Person>
          </dd>
          <div v-else>No address on file.</div>
        </div>
        <div class="col-6" v-if="getHasVBA">
          <dt>Virtual Address<br/>(use this in place of mailing address on forms)</dt>
          <dd>
            <Person :person="addresses['virtual'] || {}" address-type="virtual"></Person>
          </dd>
        </div>
      </div>
    </div>
    <h2 class="header">Ownership and Management</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dt>Management Structure</dt>
          <dd>{{ getManagerType }}</dd>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <dt>Members</dt>
          <dd v-for="(member, index) in contacts?.member || []" v-bind:key="member.contact_uuid">
            <Person :person="member" :view="memberView" :getManagerType="getManagerType">
              <template v-slot:update-contact-button>
                <UpdateAddressOrContactButton
                  v-if="displayUpdateAddressOrContactButton && member?.contact_uuid"
                  :entity="'contact'"
                  :subEntity="'contact'"
                  :recordType="'member'"
                  :recordUuid="member.contact_uuid"
                  @openJourneyModal="openJourneyModal"
                >
                </UpdateAddressOrContactButton>
              </template>
              <template v-slot:update-address-button>
                <UpdateAddressOrContactButton
                  v-if="displayUpdateAddressOrContactButton && member?.contact_uuid"
                  :entity="'contact'"
                  :subEntity="'address'"
                  :recordType="'member'"
                  :recordUuid="member.contact_uuid"
                  @openJourneyModal="openJourneyModal"
                >
                </UpdateAddressOrContactButton>
              </template>
            </Person>
            <span v-if="index !== contacts?.member?.length - 1"><hr/></span>
          </dd>
        </div>
        <div v-if="!getManagerType.includes('Member managed')" class="col">
          <dt>Managers</dt>
          <dd v-for="(manager, index) in contacts?.manager || []" v-bind:key="manager.contact_uuid">
            <Person :person="manager" :view="managerView">
              <template v-slot:update-contact-button>
                <UpdateAddressOrContactButton
                  v-if="displayUpdateAddressOrContactButton && manager?.contact_uuid"
                  :entity="'contact'"
                  :subEntity="'contact'"
                  :recordType="'manager'"
                  :recordUuid="manager.contact_uuid"
                  @openJourneyModal="openJourneyModal"
                >
                </UpdateAddressOrContactButton>
              </template>
              <template v-slot:update-address-button>
                <UpdateAddressOrContactButton
                  v-if="displayUpdateAddressOrContactButton && manager?.contact_uuid"
                  :entity="'contact'"
                  :subEntity="'address'"
                  :recordType="'manager'"
                  :recordUuid="manager.contact_uuid"
                  @openJourneyModal="openJourneyModal"
                >
                </UpdateAddressOrContactButton>
              </template>
            </Person>
            <span v-if="index !== contacts?.manager?.length - 1"><hr/></span>
          </dd>
        </div>
      </div>
      <div class="row" v-if="daoDesignation" >
        <hr />
        <div class="col dao-designation-box">
          <DaoDesignation :orderTask="daoDesignation" />
        </div>
      </div>
      <div class="row" v-if="hasOwnershipPercentages">
        <div class="col">
          <hr />
          <dt>Ownership Percentages</dt>
          <table class="table table-sm table-borderless table-ownership">
            <thead>
              <tr>
                <th>First</th>
                <th>Middle</th>
                <th>Last</th>
                <th>Suffix</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, index) in ownership" v-bind:key="index">
                <td>{{ member.first_name }}</td>
                <td>{{ member.middle_name }}</td>
                <td>{{ member.last_name }}</td>
                <td>{{ member.suffix }}</td>
                <td>{{ member.ownership_percentage || 0 }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <h2 class="header">Contact</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dd><Person :person="contacts?.primary?.[0] || {}">
            <template v-slot:update-contact-button>
              <UpdateAddressOrContactButton
                v-if="displayUpdateAddressOrContactButton && contacts?.primary?.[0]?.contact_uuid"
                :entity="'contact'"
                :subEntity="'contact'"
                :recordType="'primary'"
                :recordUuid="contacts?.primary?.[0].contact_uuid"
                @openJourneyModal="openJourneyModal"
              >
              </UpdateAddressOrContactButton>
            </template>
            <template v-slot:update-address-button>
              <UpdateAddressOrContactButton
                v-if="displayUpdateAddressOrContactButton && contacts?.primary?.[0]?.contact_uuid"
                :entity="'contact'"
                :subEntity="'address'"
                :recordType="'primary'"
                :recordUuid="contacts?.primary?.[0].contact_uuid"
                @openJourneyModal="openJourneyModal"
              >
              </UpdateAddressOrContactButton>
            </template>
          </Person></dd>
        </div>
      </div>
    </div>
    <h2 class="header">Authorized Signatory</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <dd v-if="contacts?.authorized_signatory?.[0]">
            Titles: {{ resolveTitles(contacts?.authorized_signatory?.[0]?.titles) || 'None on file.' }}
            <Person :person="contacts?.authorized_signatory?.[0] || {}">
              <template v-slot:update-contact-button>
                <UpdateAddressOrContactButton
                  v-if="displayUpdateAddressOrContactButton && contacts?.authorized_signatory?.[0]?.contact_uuid"
                  :entity="'contact'"
                  :subEntity="'contact'"
                  :recordType="'authorized_signatory'"
                  :recordUuid="contacts?.authorized_signatory?.[0].contact_uuid"
                  @openJourneyModal="openJourneyModal"
                >
                </UpdateAddressOrContactButton>
              </template>
              <template v-slot:update-address-button>
                <UpdateAddressOrContactButton
                  v-if="displayUpdateAddressOrContactButton && contacts?.authorized_signatory?.[0]?.contact_uuid"
                  :entity="'contact'"
                  :subEntity="'address'"
                  :recordType="'authorized_signatory'"
                  :recordUuid="contacts?.authorized_signatory?.[0].contact_uuid"
                  @openJourneyModal="openJourneyModal"
                >
                </UpdateAddressOrContactButton>
              </template>
            </Person>
          </dd>
          <dd v-else>
            None on file.
          </dd>
        </div>
      </div>
    </div>
    <template v-if="contacts?.registered_agent?.[0]">
      <h2 class="header">Registered Agent</h2>
      <div class="box">
        <div class="row">
          <div class="col">
            <dd><Person :person="contacts?.registered_agent?.[0] || {}"></Person></dd>
          </div>
        </div>
      </div>
    </template>
    <Modal @close="closeDaoModal" :fullWidth="false" :showOverflow="false" v-if="showDaoWarning">
      <template v-slot:title>
        <h5>LLC FORMATION HAS DAO DESIGNATION</h5>
      </template>
      <template v-slot:body>
        <p>This entity is formed with a Dao Designation. </p>
      </template>
      <template v-slot:footer>
        <ZgButton @click="closeDaoModal" class="btn btn-info action">
          <template v-slot:title>
          OK
          </template>
        </ZgButton>
      </template>
    </Modal>
    <Modal @close="closeRaModal" :showOverflow="false" v-if="showRaWarning">
      <template v-slot:title>
        <h5>Please be advised that the Registered Agent information has changed from the original order.</h5>
      </template>
      <template v-slot:body>
        <h6>The new information is:</h6>
        <p>
          <b>Company Name: </b>{{ updatedRaInfo.company_name }} <br>
          <b>Phone: </b>{{ updatedRaInfo.phone }}<br>
          <b>Email: </b>{{ updatedRaInfo.email }}<br>
          <b>Address:</b><br>
          {{ updatedRaInfo.address_1 }}<br>
          {{ updatedRaInfo.address_2 }}<br>
          <b>City: </b>{{ updatedRaInfo.city }}<br>
          <b>State: </b>{{ updatedRaInfo.state }}<br>
          <b>Zip: </b>{{ updatedRaInfo.zip }}
      </p>
      </template>
      <template v-slot:footer>
        <h5>This information is also available in the registered_agent_service order task.</h5>
        <ZgButton @click="closeRaModal" class="btn btn-info action">
          <template v-slot:title>
          OK
          </template>
        </ZgButton>
      </template>
    </Modal>
    <UpdateAddressOrContactModal
      :isJourneyUpdateSuccess="isJourneyUpdateSuccess"
      :isJourneyUpdateSubmitting="isJourneyUpdateSubmitting"
      :modalData="modalData"
      v-if="isJourneyModalOpen"
      @closeJourneyModal="closeJourneyModal"
      @updateJourneyService="updateJourneyService(modalData)"
    />
  </div>

</template>

<script>
import { getMonth, addMinutes } from 'date-fns';
import { mapState, mapGetters, mapActions } from 'vuex';
import Person from '../../common/person';
import AutomatedFiling from '../../common/automated-filing';
import {
  automatedFilingMixin, computeFullNameOfPersonMixin, getContactTitlesMixin, hasPermissionMixin, managementStructureMixin, naicsCodesMixins
} from '@/components/mixins';
import DaoDesignation from './dao-designation'
import OrderTaskFulfillment from './order-task-fulfillment.vue';
import ReportValidationMessages from '@/v2/automation/components/ReportValidationMessages';
import Modal from '@/components/common/Modal';
import ZgButton from '../../common/Generics/ZgButton'
import { getClient } from "@/client/ldClient";
import { minJourneyEnabled } from "@/assets/utils/feature-utils";
import UpdateAddressOrContactModal from "@/components/business-entities/UpdateAddressOrContactModal.vue";
import UpdateAddressOrContactButton from "@/components/business-entities/UpdateAddressOrContactButton.vue";
import SlotCopy from '@/components/v2/common/Generics/SlotCopy';

export default {
  name: 'LlcFormationView',

  components: {
    ZgButton,
    DaoDesignation,
    AutomatedFiling,
    OrderTaskFulfillment,
    Person,
    Modal,
    ReportValidationMessages,
    SlotCopy,
    UpdateAddressOrContactButton,
    UpdateAddressOrContactModal,
  },

  mixins: [automatedFilingMixin, computeFullNameOfPersonMixin, getContactTitlesMixin, hasPermissionMixin, managementStructureMixin, naicsCodesMixins],

  props: ['orderTask', 'accountUuid', 'businessEntityUuid'],
  data () {
    return {
      'daoWarningIsOpen': true,
      'raWarningIsOpen': false,
      journeyData: {},
      journeyModalData: {},
      isJourneyModalOpen:false,
      isJourneyUpdateSubmitting: false,
      isJourneyUpdateSuccess: true,
      featureFlagClient: null,
      isFeatureEnabled: false,
      enableMiniJourneyFeatureFlag: false,
      managerView:'managerView',
      memberView: 'memberView',
      modalData: {},
      updatedRaInfo: {},
      MONTH_MAPPING: {
        0: 'January',
        1: 'February',
        2: 'March',
        3: 'April',
        4: 'May',
        5: 'June',
        6: 'July',
        7: 'August',
        8: 'September',
        9: 'October',
        10: 'November',
        11: 'December'
      }
    }
  },
  async created() {
    this.featureFlagClients = await getClient();
    this.enableMiniJourneyFeatureFlag = minJourneyEnabled( this.featureFlagClients);
  },
  async beforeMount() {

    await this.hasNewRaData();
  },
  computed: {
    ...mapGetters({
      authorizedSignatory: 'businessEntities/businessEntityAuthorizedSignatory',
      compareContactData: 'businessEntities/compareContactData',
      compareListsOfContactData: 'businessEntities/compareListsOfContactData',
      compareEntityData: 'businessEntities/compareEntityData',
      registeredAgent: 'orders/getRegisteredAgentData',
      getAutomatedFiling: 'orders/getAutomatedFiling',
      getAddressOfType: 'businessEntities/getAddressOfType',
      getUuidOrder: 'orders/getUuidOrder',
      getUuidBusinessEntity: 'orders/getUuidBusinessEntity',
      getHasVBA: 'businessEntities/getHasVBA',
      getManagerType: 'businessEntities/getManagerType',
      daoDesignation: 'orders/getDaoDesignation',
      getAutomationMessagesByOrderTaskType: 'automation/getAutomationMessagesByOrderTaskType',
    }),
    ...mapState({
      businessEntity: (state) => state.businessEntities.businessEntity,
      businessEntityTasks: (state) => state.businessEntities.businessEntityTasks,
      jurisdiction: (state) => state.businessEntities.jurisdictions[0]
    }),
    automationMessages() {
      const type = this.orderTask.order_task_type;
      const messages = this.getAutomationMessagesByOrderTaskType(type);
      return messages ? messages : [];
    },
    hasOwnershipPercentages() {
      const members = this.orderTask?.order_task_data?.members || [];
      return members.some((member) => typeof member?.ownership_percentage === 'number');
    },
    ownership() {
      const members = this.orderTask?.order_task_data?.members;
      return members ? members : [];
    },
    contacts() {
      const contactDict = this.getContacts().map((contact) => {
        let contactData = this.llcFormation.members?.find((member) => (
          member.first_name === contact.contact.first_name
            && member.last_name === contact.contact.last_name
            && member.email === contact.contact.email
        ));

        if (!contactData) {
          contactData = this.llcFormation.managers?.find((member) => (
            member.first_name === contact.contact.first_name
              && member.last_name === contact.contact.last_name
              && member.email === contact.contact.email
          ));
        }

        if (contactData) {
          contact.contact['outstanding_judgments'] = contactData.outstanding_judgments
        }

        return contact;
      }).reduce((acc, {contact, contact_type}) => {
        if(acc[contact_type]){
          acc[contact_type].push(contact);
        } else {
          acc[contact_type] = [contact];
        }
        return acc;
      }, {});
      return contactDict
    },
    addresses(){
      return this.getAddresses().reduce((acc, {address, address_type}) => {
        acc[address_type] = address;
        return acc;
      }, {});
    },
    contactTypes() {
      return this.getContactTypes().reduce((acc, {contact_type_name_id, display_name}) => {
        acc[contact_type_name_id] = display_name;
        return acc;
      }, {});
    },
    jurisdiction_code() {
      if (!this.llcFormation.jurisdiction) {
        return '';
      }
      return this.llcFormation.jurisdiction;
    },
    llcFormation() {
      if (!this.orderTask || !this.orderTask.order_task_data) {
        return '';
      }
      return this.orderTask.order_task_data;
    },
    fiscalEndOfYearMonth() {
      if (!this.llcFormation.fiscal_year_end_month) {
        return this.MONTH_MAPPING[11] // return december by default
      }
      // Date coming from db is in utc, need to handle that
      const localDate = new Date(this.llcFormation.fiscal_year_end_month);
      const offsetInMinutes = localDate.getTimezoneOffset();

      return this.MONTH_MAPPING?.[getMonth(addMinutes(localDate, offsetInMinutes))];
    },
    agencyLoginSelectionEnabled() {
      return this.jurisdiction_code && ['CA', 'PA'].includes(this.jurisdiction_code);
    },
    hasManagersAndFL() {
      if (this.jurisdiction_code === 'FL') {
        return this.llcFormation.member_count + this.llcFormation.manager_count > 6;
      }
      return false;
    },
    ifFutureFileDate() {
      return this.orderTask?.jurisdiction_future_filing_date || '';
    },
    orderTaskStatus() {
      return this.orderTask?.order_task_status || '';
    },
    filingAction() {
      if (!this.jurisdiction || !('filing_action' in this.jurisdiction)) {
        return ''
      }
      if (this.jurisdiction.filing_action === 'hold') {
        return 'Hold (Do not file paperwork until future filing date)';
      } if (this.jurisdiction.filing_action === 'immediate') {
        return 'Immediate (File paperwork now with the effective future filing date)';
      }
      return this.jurisdiction.filing_action;
    },
    comparedBusinessEntityName() {
      return this.compareEntityData(this.llcFormation.entity_name, 'business_entity_name')
    },
    comparedBusinessPurpose() {
      return this.compareEntityData(this.llcFormation.business_purpose, 'business_purpose')
    },
    showDaoWarning() {
      return this.daoWarningIsOpen && this.daoDesignation
    },
    showRaWarning() {
      return this.raWarningIsOpen
    },
    displayUpdateAddressOrContactButton() {
      return this.enableMiniJourneyFeatureFlag && this.businessEntity.formation_status === 'not_formed'
    }
  },
  methods: {
    ...mapActions({
      fetchOrder: 'orders/fetchOrder',
      fetchBusinessEntity: 'businessEntities/fetchBusinessEntity',
    }),
    ...mapActions('orders', [
      'fetchOrderTasksByAccount', 'fetchOrderTaskV2'
     ]),
     ...mapGetters('businessEntities', [
      'getContacts', 'getContactTypes', 'getAddresses']),
    getMonth,
    addMinutes,
    closeDaoModal() {
      this.daoWarningIsOpen = false
    },
    closeRaModal() {
      this.raWarningIsOpen = false
    },
    resolveTitles(titleEnums = []){
      return titleEnums.map(title => this.contactTypes[title]).join(', ');
    },
    async hasNewRaData () {
      try {
        const res = await this.fetchOrderTasksByAccount({ accountUuid: this.accountUuid, bypassStoreCommit:true})
        const orderTasks = res.data.results

        const raOrderTask = orderTasks.filter(
          ot => ot.order_task_type === 'registered_agent_service' &&
          ot.order_task_jurisdiction === this.orderTask.order_task_jurisdiction &&
          ot.order_uuid !== this.orderUuid &&
          ot.business_entity_uuid === this.businessEntityUuid &&
          ot.order_task_status !== 'canceled' &&
          ot.order_task_status !== 'incomplete')[0]

        // Check if RA order task was created after the LLC formation order task, if so, add correct RA data for pop up
        if (raOrderTask && Date.parse(raOrderTask.created_datetime) > Date.parse(this.orderTask.created_datetime)) {
          // Use v2 method to grab order task data property values
          const raTask = await this.fetchOrderTaskV2({"orderTaskUuid": raOrderTask.order_task_uuid})
          const raOrderTaskData = raTask.data.order_task_data.registered_agent
          this.raWarningIsOpen = true;
          this.updatedRaInfo = {...this.updatedRaInfo, ...raOrderTaskData}
        }
         return Promise.resolve();
      } catch (error) {
        console.log("Error in hasNewRaData", error.message)
        return Promise.resolve();
      }

    },
    openJourneyModal(journeyData) {
      this.modalData = journeyData;
      this.isJourneyUpdateSuccess = true;
      this.isJourneyModalOpen = true;
    },
    closeJourneyModal() {
      this.modalData = null;
      this.isJourneyUpdateSubmitting = false
      this.isJourneyUpdateSuccess = true
      this.isJourneyModalOpen = false
    },

    async updateJourneyService (modalData) {
      this.isJourneyUpdateSubmitting = true

      try {
        const businessEntityUuid = this.businessEntity.business_entity_uuid;
        const accountUuid = this.businessEntity.account_uuid;
        const orderTask = this.businessEntityTasks.find(task => (task.order_task_type === 'llc_formation') || (task.order_task_type === 'corp_formation') && !['canceled'].includes(task.order_task_status))
        const orderTaskUuid = orderTask?.order_task_uuid;
        const orderUuid = orderTask?.order_uuid;

        const payload = {
          entity: modalData.entity,
          journey: modalData.subEntity,
          recordUuid: modalData.recordUuid,
          recordType: modalData.recordType,
          businessEntityUuid,
          accountUuid,
          orderUuid,
          orderTaskUuid,
        };
        await this.$store.dispatch('businessEntities/createAddressContactJourney', payload);
        this.closeJourneyModal();

      } catch (error) {
        this.closeJourneyModal();
      }
    }
  },
  mounted() {
    this.fetchOrder({ uuid: this.getUuidOrder });
    this.fetchBusinessEntity({ uuid: this.getUuidBusinessEntity });

  }
};
</script>

<style lang="scss" scoped>
.table-ownership {
  th {
    font-size: 9pt;
    color: #969696;
  }
}
dt {
  font-size: 10pt;
  color: $clr-font-secondary;
}
div.dao-designation-box {
  background-color: rgba($clr-warning, .25);
}

</style>
