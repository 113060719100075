<template>
  <div class="box-wrapper-row">
    <div class="row">
      <div class="col">
        <dt>Automate Filing</dt>
        <dd>
          <ZgButton
            class="zg-btn-dark zg-text-sm"
            @click="beginFiling"
            type="button"
            :isEnable="beginButton"
          >
            <template v-slot:title>Begin</template>
          </ZgButton>
        </dd>
      </div>
      <div class="col">
        <dt>Status</dt>
        <dd v-if="filingStatus === FILING_SUBMISSION_STATUSES.INCOMPLETE"><span class="badge bg-secondary">Incomplete</span></dd>
        <dd v-if="filingStatus === FILING_SUBMISSION_STATUSES.PENDING"><span class="badge bg-secondary">Pending</span></dd>
        <dd v-if="filingStatus === FILING_SUBMISSION_STATUSES.COMPLETED">
          <span class="badge bg-success">Pass <i class="fas fa-check-circle icon-pass"></i></span>
        </dd>
        <dd v-if="filingStatus === FILING_SUBMISSION_STATUSES.FAILED">
          <span class="badge bg-danger">Failed <i class="fas fa-times-circle icon-fail"></i></span>
        </dd>
        <dd v-if="filingStatus === FILING_SUBMISSION_STATUSES.REJECTED">
          <span class="badge bg-danger">Rejected <i class="fas fa-times-circle icon-fail"></i></span>
        </dd>
      </div>
      <div class="col">
        <dt>Notes</dt>
        <div class="resizable-box" ref="box" v-if="filingNotes">
          <dd>{{ filingNotes }}</dd>
          <div class="resize-handle" @mousedown="startResize">
            <i class="fas fa-expand-arrows-alt"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FILING_SUBMISSION_STATUSES } from '@/assets/constants/bofFilings';
import ZgButton from '../common/Generics/ZgButton.vue';
import { mapActions, mapState } from 'vuex';

export default {
name: 'BofFilingAutomation',
props: ['orderTaskUUID', 'businessEntityUUID', 'businessEntityJurisdiction'],
  components: {
    ZgButton,
  },
  computed: {
   ...mapState('bofAutomatedFilings', ['beginButton', 'filingStatus', 'filingNotes']),
    FILING_SUBMISSION_STATUSES() {
      return FILING_SUBMISSION_STATUSES;
    },
  },
  created() {
   this.getFilingStatus();
  },

  methods: {
   ...mapActions({
      submitBofData: 'bofAutomatedFilings/submitBofData',
      fetchFilingStatus: 'bofAutomatedFilings/fetchFilingStatus'
    }),
    getFilingStatus() {
      this.fetchFilingStatus({orderTaskUuid: this.orderTaskUUID});
    },
    beginFiling() {
    this.submitBofData({
      orderTaskUuid: this.orderTaskUUID,
      businessEntityUuid: this.businessEntityUUID,
      businessEntityJurisdictionUuid: this.businessEntityJurisdiction[0].jurisdiction_uuid,
      formationDate: this.businessEntityJurisdiction[0].jurisdiction_filing_date
    }).finally(() => {
       this.$store.commit('bofAutomatedFilings/setFilingNotes', '');
      });
    },
    startResize(event) {
      event.preventDefault();

      const box = this.$refs.box;
      const startX = event.clientX;
      const startY = event.clientY;
      const startWidth = box.offsetWidth;
      const startHeight = box.offsetHeight;

      const onMouseMove = (e) => {
        const newWidth = startWidth + (e.clientX - startX);
        const newHeight = startHeight + (e.clientY - startY);
        box.style.width = `${newWidth}px`;
        box.style.height = `${newHeight}px`;
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    }
  },

};
</script>
<style lang="scss" scoped>

.row {
  /* cause component to be full width */
  flex: 0 0 100%;
  padding: 0 16px;
}

.zg-btn-dark {
  border-radius: 12px;
  font-size: 14px;
  height: 36px;
  width: 80px;
}

dt {
  color: #212529;
  font-size: 16px;
  margin-bottom: 16px;
}

.resizable-box {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  height: 150px;
  overflow: auto;
  padding: 16px;
  position: relative;
  width: 300px;
}

.resize-handle {
  align-items: center;
  background: #17a2b8;
  bottom: 0;
  color: white;
  cursor: se-resize;
  display: flex;
  height: 20px;
  position: absolute;
  right: 0;
  width: 20px;
}

.resize-handle i {
  font-size: 12px;
}
</style>
